@import "bootstrap-custom.css";

@font-face {
  font-family: Roboto Slab;
  font-weight: 300;
  src: url("/fonts/RobotoSlab-Light.ttf") format("truetype");
  font-display: swap;
}
@font-face {
  font-family: Roboto Slab;
  font-weight: 400;
  src: url("/fonts/RobotoSlab-Regular.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: Roboto Slab;
  font-weight: 500;
  src: url("/fonts/RobotoSlab-Medium.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: Roboto Slab;
  font-weight: 600;
  src: url("/fonts/RobotoSlab-SemiBold.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: Roboto Slab;
  font-weight: 700;
  src: url("/fonts/RobotoSlab-Bold.ttf") format("truetype");
  font-display: swap;
}

html,
body {
  margin: 0;
  height: 100%;
  background-color: #fff;
  font-family: Roboto Slab, -apple-system, BlinkMacSystemFont, Roboto,
    Segoe UI, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
    sans-serif !important;
}

#__next {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.medium-down-ratio {
  height: 100%;
}

@media (max-width: 992px) {
  .medium-down-ratio {
    aspect-ratio: 16 / 9;
  }
}

.post-article .image {
    display: table;
    clear: both;
    text-align: center;
    margin: 0.9em auto;
    min-width: 50px;
}

.post-article .image img {
  display: block;
  margin: 0 auto;
  max-width: 100%;
}

.post-article img {
  display: block;
  margin: 0 auto;
  max-width: 100%;
}